.footer-container {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.footer-name {
    font-family: var(--playfair-font), serif;
    font-size: 22px;
}

.email {
    font-size: 15px;
    font-family: var(--montserrat-font), sans-serif;
    color: black;
    text-decoration: none; /* Remove underline */
    outline: none; /* Remove outline on focus (optional) */
}

.email:hover {
    text-decoration: underline;
}