.nav-bar {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 30px;
}


.logo-image {
    height: 50px;
    margin-right: 22px;
}

.name-title {
    font-size: 22px;
    color: #ffffff;
    font-family: var(--playfair-font), serif;
    font-stretch: 80%;
}

.nav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

.social-icon {
    height: 20px;
    width: 20px;
    margin-right: 7px;
    cursor: pointer;
}

.nav-links {
    display: flex;
    align-items: center;
}