.app {
  background-color: white;
}

.page {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  margin-top: 50px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./fonts/Montserrat/static/Montserrat-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: local('Playfair Display'),
  url('./fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: local('Playfair Display'),
  url('./fonts/Playfair_Display/static/PlayfairDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Playfair Display';
  src: local('Playfair Display'),
  url('./fonts/Playfair_Display/static/PlayfairDisplay-Italic.ttf') format('truetype');
  font-style: italic;
}