.mobile-christmas-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
    box-sizing: border-box;
}

.mobile-christmas-title{
    color: var(--greenColor);
    font-size: 36px;
    font-family: var(--playfair-font), serif;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
}

.mobile-christmas-body{
    color: #000000;
    font-family: var(--montserrat-font), sans-serif;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
}

.mobile-wreath-img {
    height: 150px;
}

.mobile-christmas-desc {
    color: black;
    font-size: 15px;
    font-family: var(--montserrat-font), sans-serif;
}

.mobile-christmas-link {
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.mobile-christmas-link:hover {
    text-decoration: underline;
}

.mobile-christmas-body-container {
    margin-top: 20px;
}