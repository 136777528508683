.introduction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.profile-pic {
    height: 420px;
}

.not-found-title{
    color: var(--greenColor);
    font-size: 72px;
    font-family: var(--playfair-font), serif;
    margin-bottom: 20px;
}

.explore-text{
    color: #000000;
    font-family: var(--montserrat-font), sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
}

.grab-snack-text{
    color: black;
    font-size: 15px;
    font-family: var(--montserrat-font), sans-serif;
}

.intro-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    margin-left: 100px;
}

.contact-form-container {
    margin-bottom: 50px;
}