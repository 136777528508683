.contact-email {
    font-size: 15px;
    font-family: var(--montserrat-font), sans-serif;
    color: var(--blackColor);
    text-decoration: none;
    outline: none;
    margin-bottom: 20px;
}

.contact-email:hover {
    text-decoration: underline;
}

.contact-info-body {
    color: var(--blackColor);
    font-size: 15px;
    font-family: var(--montserrat-font), sans-serif;
    margin-bottom: 20px;
    line-height: 2;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
}

.contact-info-title {
    color: var(--blackColor);
    font-size: 40px;
    font-family: var(--playfair-font), serif;
    margin-bottom: 30px;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    max-width: 550px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 50px;
    height: 100%;
    width: 50%;
    max-width: 550px;
}

.contact-container {
    border-top: 5px solid var(--blueColor);
    border-bottom: 5px solid var(--blueColor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 40px;
    margin-left: 80px;
    margin-right: 80px;
    background-color: var(--whiteColor);
}

.contact-input {
    margin-left: 50px;
    margin-right: 50px;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 3px solid var(--blueColor);;
    background-color: transparent;
    color: var(--blackColor);
    height: 30px;
    outline: none;
    font-family: Arial, serif;
    font-size: 15px;
    margin-bottom: 30px;
    padding: 3px 3px 3px 12px;
    box-sizing: border-box;
}

.contact-input::placeholder {
    color: var(--blackColor);
    font-family: var(--montserrat-font), serif;
    font-weight: 200;
    font-size: 15px;
}

.contact-input:hover {
    border: 3px solid var(--blueColor);
}

.contact-input:focus {
    border: 3px solid var(--blueColor);
}

.contact-input-text-area {
    height: 80px;
}

.contact-submit-button {
    margin-left: 50px;
    margin-right: 50px;
    width: 100%;
    height: 50px;
    font-family: var(--montserrat-font), sans-serif;
    font-size: 18px;
    outline: none;
    background-color: var(--whiteColor);
    cursor: pointer;
    border: 3px solid var(--blueColor);
    color: var(--blackColor);
}

.contact-submit-button:hover {
    background-color: rgba(211, 211, 211, 0.7);
}