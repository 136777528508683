.project-page-title {
    font-family: var(--playfair-font), serif;
    color: var(--greenColor);
    font-size: 72px;
    max-width: 780px;
    text-align: center;
}

.project-page-subtitle {
    font-family: var(--montserrat-font), serif;
    font-size: 20px;
    margin-top: 40px;
    max-width: 780px;
    text-align: center;
}

.project-page-description {
    font-family: var(--montserrat-font), sans-serif;
    font-size: 15px;
    margin-top: 35px;
    white-space: pre-line;
    max-width: 780px;
    text-align: center;
}

.project-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-section-row {
    width: calc(84vw + 90px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.project-rows-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.project-section-title {
    font-family: var(--montserrat-font);
    font-weight: bold;
    font-size: 30px;
    color: var(--greenColor);
    margin-top: 40px;
    margin-bottom: 20px;
}
