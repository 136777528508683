.christmas-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.christmas-title{
    color: var(--greenColor);
    font-size: 72px;
    font-family: var(--playfair-font), serif;
    margin-bottom: 20px;
}

.christmas-body{
    color: #000000;
    font-family: var(--montserrat-font), sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
}

.wreath-img {
    height: 300px;
}

.christmas-desc {
    color: black;
    font-size: 15px;
    font-family: var(--montserrat-font), sans-serif;
}

.christmas-link {
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.christmas-link:hover {
    text-decoration: underline;
}

.christmas-body-container {
    margin-top: 30px;
}