.mobile-footer-container {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.mobile-footer-border {
    border-top: 1px solid black;
    width: 70%;
    height: 10px;
}

.mobile-footer-name {
    font-family: var(--playfair-font), serif;
    font-size: 22px;
}

.mobile-email {
    font-size: 15px;
    font-family: var(--montserrat-font), sans-serif;
    color: black;
    text-decoration: none; /* Remove underline */
    outline: none; /* Remove outline on focus (optional) */
}

.mobile-email:hover {
    text-decoration: underline;
}

.mobile-footer-social-icon {
    height: 18px;
    margin-right: 6px;
}