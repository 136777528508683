.project-tile-img {
    width: 28vw;
    height: 28vw;
    object-fit: cover;
}

.project-tile {
    position: relative;
    width: 28vw;
    height: 28vw;
    display: inline-block;
    margin: 15px;
    cursor: pointer;
}


.project-tile-overlay {
    position: absolute; /* Position the overlay absolutely within the container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    cursor: pointer;
    color: white; /* Set text color for overlay content */
    /* Add any additional styling for the overlay content */
}

/* Add transition for smooth effect (optional) */
.project-tile-overlay {
    transition: background-color 0.3s ease;
}

.project-tile-overlay-title {
    font-family: var(--montserrat-font);
    font-size: 1.3rem;
}
