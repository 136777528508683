.page-link-text {
    color: #000000;
    text-decoration: none;
}

.clickable-text {
    cursor: pointer;
}

.not-clickable-text {
    cursor: default;
}

.selected-nav-link {
    border-bottom: 5px solid var(--blueColor);
    padding-bottom: 3px;
}

.nav-link-container {
    display: inline-block;
    margin-right: 20px;
    position: relative;
}

.child-list {
    position: absolute;
    right: 0;
    list-style: none;
    margin: 0;
    padding: 5px 0 0;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999999;
}

.child-list li {
    padding: 5px 10px;
    color: #000000;
    cursor: pointer;
    white-space: nowrap;
    text-align: right;
}

.child-list li:hover {
    color: #747474;
}
