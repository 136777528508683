.edit-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
    box-sizing: border-box;
}

.import-component-container {
    margin-bottom: 20px;
    width: 100%;
}

.import-component-container > input {
    margin-left: 20px;
    width: 70vw;
}

.title-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

#pageDropdown {
    width: 200px;
    margin-bottom: 20px;
}

.edit-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

.input-section-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}

.delete-section-button-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding-bottom: 15px;
    width: 100%;
}

.delete-button-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    width: 100%;
}

.input-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

#mediaType {
    margin-left: 10px;
}