.project-overlay-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    max-width: 100vw;
    height: 100%;
    background-color: white;
}

.project-overlay-img {
    max-width: 100%;
}

.project-overlay-title {
    font-family: var(--playfair-font), serif;
    font-size: 40px;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
}

.project-overlay-subtitle {
    font-family: var(--playfair-font), serif;
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
}

.project-overlay-description-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 60px;
    padding-right: 60px;
    width: 100%;
    box-sizing: border-box;
}

.sub-media-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.submedia-image-container {
    margin-right: 10px;
    margin-bottom: 10px;
}


.project-paragraphs-container {
    display: flex;
    max-width: 1500px;
    flex-direction: row;
    justify-content: space-between;
}

.project-paragraph-column {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    width: fit-content;
    padding-right: 50px;
    box-sizing: border-box;
}

.project-paragraph-container {
    width: fit-content; /* Set width to fit its content */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the flex container */
}

.project-overlay-paragraph-title {
    font-family: var(--montserrat-font), sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    display: block;
}

.project-overlay-paragraph-text {
    font-size: 1.1rem;
    margin-bottom: 16px;
    white-space: pre-line;
    line-height: 1.15;
    display: block;
}


.button-bar-container {
    display: flex;
    padding: 40px 40px 0;
}

.project-overlay-text-container {
    height: 100%;
    min-width: 255px;
    max-width: 255px;
    padding-right: 120px;
    padding-left: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.project-navigation-icon {
    font-family: var(--montserrat-font), sans-serif;
    font-size: 35px;
    cursor: pointer;
}

.go-back-span {
    padding: 15px;
    font-size: 50px;
    margin-right: 20px;
}

.go-forward-span {
    padding: 15px;
    font-size: 50px;
    margin-left: 20px;
}

.nav-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70%;
    align-items: center;
}

.overlay-image-nav-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.overlay-content-container {
    margin-top: 42px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.project-image {
    max-width: 100vw;
    max-height: 70%;
    object-fit: contain;
}

.overlay-title {
    font-family: var(--montserrat-font), sans-serif;
    font-size: 1.4rem;
    margin-bottom: 18px;
}

.overlay-description {
    font-family: var(--montserrat-font), sans-serif;
    font-size: 1.2rem;
    white-space: pre-line;
}

.project-overlay-navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 40px;
}

.project-overlay-nav-icon-text {
    font-family: var(--montserrat-font), sans-serif;
    font-size: 120px;
    font-weight: bold;
    margin-left: 10px;
    color: var(--blueColor);
}

.project-overlay-nav-next {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}

.project-overlay-nav-previous {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.project-overlay-nav-description {
    font-family: var(--montserrat-font), sans-serif;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.5rem;
}

.submedia-image {
    object-fit: cover;
}

.project-overlay-sub-media-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    box-sizing: border-box;
}

.project-overlay-sub-media-row {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
}

.submedia-orientation-square {
    height: 500px;
    width: 500px;
}

.submedia-orientation-horizontal {
    height: 500px;
    width: 1010px;
}

.submedia-orientation-vertical {
    height: 1015px;
    width: 500px;
}

.submedia-image-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.submedia-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    color: white;
}

.project-overlay-sub-media-empty {
    height: 500px;
    width: 500px;
}

.project-overlay-sub-media-header {
    width: 500px;
}