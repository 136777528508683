.mobile-nav-bar {
    height: 50px;
    background-color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* You can adjust the z-index based on your layout */
}

.mobile-logo-image {
    height: 30px;
    margin-left: 20px;
    cursor: pointer;
}

.mobile-hamburger-icon {
    height: 25px;
    margin-right: 20px;
    cursor: pointer;
}

.mobile-name-title {
    font-size: 22px;
    color: #ffffff;
    font-family: var(--playfair-font), serif;
    font-stretch: 80%;
    cursor: pointer;
}

.mobile-pages-list {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Black with 80% transparency */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.mobile-pages-list ul {
    list-style: none;
    padding: 0;
    text-align: center;
}

.mobile-pages-list li {
    margin: 20px 0;
}

.mobile-pages-list a,
.mobile-pages-list span {
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

.mobile-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 54px;
    color: #ffffff;
    cursor: pointer;
}